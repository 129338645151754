import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import Figure from "../components/Figure"
import { H1, H2, H3, P } from "../components/VerticalRhythm"
import { Flex, Box } from "rebass"

import PictureSet from "../components/PictureSet"
import imgObj01 from "../imgObjs/imgObj01"
import imgObj02 from "../imgObjs/imgObj02"
import imgObj03 from "../imgObjs/imgObj03"
import imgObj04 from "../imgObjs/imgObj04"
import imgObj05 from "../imgObjs/imgObj05"
import imgObj06 from "../imgObjs/imgObj06"
import imgObj07 from "../imgObjs/imgObj07"

import imgGM from "../img/0600/3x4/Geesche-Martin.jpg"
import breakpoints from "../theme/breakpoints"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Krisenberatung"
      keywords={[
        `Psychosoziale Arbeit`,
        `Frankfurt`,
        `Beratung`,
        `Geesche Martin`,
      ]}
    />
    <Figure caption="">
      <PictureSet imgObj={imgObj01} />
    </Figure>
    <Section>
      <Flex flexDirection={"row-reverse"}>
        <Box width={[1, 1, 1 / 2]}>
          <P>
            <em>
              „Die Herausforderungen des Lebens gibt es nicht, um uns zu
              erschrecken, sondern sie sollen uns helfen zu entdecken, wer wir
              sind.“ <br />
            </em>
            — Bernice J. Reagon, afro-amerikanische Musikerin und
            Friedensaktivistin
          </P>
        </Box>
      </Flex>
      <H2>Herzlich willkommen</H2>
      <P>
        Sie durchleben eine schwierige Phase und suchen Klarheit und
        Unterstützung? Dann sind Sie bei mir richtig.
      </P>
      <P>
        Unter großen Belastungen bewegen wir uns häufig im Kreis, fühlen uns
        ohnmächtig und eine Lösung scheint unerreichbar. In Krisensituationen
        ist psychosoziale Beratung mein Hilfsangebot an Sie. Bei mir finden Sie
        Antworten auf Ihre Fragen und Trost, sowie eine Perspektive in Ihrem
        Schmerz. Sie haben Interesse an einem Gespräch und/oder Terminwünsche?
        Ich freue mich auf Ihre Nachricht.
      </P>
      <P>Herzlich</P>
      <P>
        <em>Geesche Martin</em>
      </P>
    </Section>

    <Figure id="angebot" caption="">
      <PictureSet imgObj={imgObj02} />
    </Figure>
    <Section>
      <H2>Mein Angebot</H2>
      <P>
        Als psychosoziale Beraterin biete ich Ihnen einen professionellen
        Rahmen, in dem Ihre Gedanken sortiert und Unausgesprochenes besprochen
        werden kann. Ich frage nach, ermutige und unterstütze Sie — empathisch,
        kompetent, handlungsorientiert.
      </P>
      <P>
        Gemeinsam möchte ich Ihnen Wege aufzeigen, wie Sie mit der Situation
        besser umgehen. Ihre persönlichen Ressourcen sollen Ihnen bewusst werden
        und damit wieder zur Verfügung stehen.
      </P>
      <P>
        Dabei kann ein Perspektivenwechsel für Sie nützlich sein, festgefahrene
        Muster aufzubrechen mit dem Ziel, eine positive Entwicklungsdynamik
        anzustoßen.
      </P>
      <P>
        Eine Betrachtung Ihrer Biographie ist häufig unerlässlich zum
        Verständnis der Problematik. Je nach Ihrer Situation und Problemlage
        bediene ich mich der Methoden der vier Interventionstheorien
        (verhaltensorientiert, systemisch, psychodynamisch und klientzentriert).
      </P>
    </Section>

    <Figure id="ausloeser-krisen" caption="">
      <PictureSet imgObj={imgObj03} />
    </Figure>

    <Section>
      <H3>Auslöser für Krisensituationen</H3>
      <P>Auslöser für Krisensituationen können viele sein:</P>
      <ul>
        <li>
          einschneidende Erlebnisse wie Abschied, Verlust, Tod und Trauer,{" "}
        </li>
        <li>
          Probleme in der Partnerschaft, Familie oder im beruflichen Umfeld
        </li>
        <li>eine neue Lebensphase</li>
        <li>Krankheit</li>
        <li>Schwierigkeiten bei der Entscheidungsfindung</li>
      </ul>
    </Section>

    <Figure id="haltung" caption="">
      <PictureSet imgObj={imgObj04} />
    </Figure>
    <Section>
      <H3>Meine Haltung</H3>
      <P>
        Wertschätzung und Respekt sind selbstverständliche Grundlagen meiner
        Arbeit. Ihr persönliches Erleben, Ihre Fragen und Ziele sind
        handlungsleitend. Ich möchte Sie dabei begleiten, in Ihrem Tempo die
        gewünschten Veränderungen und Verbesserungen zu erreichen. Sie bestimmen
        die Inhalte und die Intensität der Beratung.
      </P>
      <H3>Honorar</H3>
      <P>
        Für eine Sitzung von 60 Minuten erhebe ich bei Einzelberatungen eine
        Gebühr von 60 Euro. Bei Paar- und Familienberatungen erhöht sich der
        Betrag um 20 Euro. Die Anzahl der Beratungssitzungen richtet sich nach
        Ihrem Bedarf. Die Abstände zwischen den Sitzungen stimmen wir gemeinsam
        ab.
      </P>
    </Section>
    <Figure id={"vita"} caption="">
      <PictureSet imgObj={imgObj05} />
    </Figure>
    <Section>
      <H2>Vita — mein Weg</H2>
      <Flex flexDirection={"row"} flexWrap={"wrap"}>
        <Box width={[1, 1, 1 / 2, 2 / 3]}>
          <P>
            Nach meinem Examen zur Hebamme 1986 in Göttingen arbeitete ich in
            verschiedenen Städten — sowohl Krankenhäusern, als auch
            freiberuflich. Seit 1997 lebe ich in Frankfurt und gründete hier
            meine Familie.
          </P>
          <P>
            Ich berate Frauen und Paare in der Geburtsvorbereitung und in der
            Nachsorge zu Hause. In einer Praxisgemeinschaft berate ich seit 2002
            werdende Mütter und Paare bei Problemen und Beschwerden in der
            Schwangerschaft und nach schweren Geburten. (Mehr Infos unter{" "}
            <a href="http://www.hebamme-in-frankfurt.de" target="_blank">
              hebamme-in-frankfurt.de
            </a>{" "}
            )
          </P>
          <P>
            In Krisensituationen habe ich über mehrere Jahre Frauen, Paare und
            Familien bei der Caritas beraten und begleitet.
          </P>
          <P>
            Die Beratung begeistert und erfüllt mich sehr. Aufgrund des
            zunehmenden Beratungsbedarfs in Krisensituationen nimmt sie einen
            immer größeren Anteil meiner Tätigkeit ein.
          </P>
          <P>
            Immer mehr entstand in den letzten Jahren der Wunsch, mein
            Fachwissen auf ein noch breiteres Fundament zu stellen.
          </P>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} pl={[0, 0, 4]}>
          <img src={imgGM} alt="" />{" "}
        </Box>
      </Flex>
    </Section>

    <Figure id={"master"} caption="">
      <PictureSet imgObj={imgObj06} />
    </Figure>

    <Section>
      <H3>Master M.A. Psychosoziale Beratung</H3>
      <P>
        An der University of Applied Sciences in Frankfurt studierte ich ab 2015
        im Studiengang „Psychosoziale Beratung und Recht“ Fachrichtung „Beratung
        und Psychotherapie“ und schloss als Master of Arts im Februar 2018 mit
        sehr gutem Ergebnis ab. Schwerpunkt meiner Masterthese war das Thema
        „Trauma und Trauer“.
      </P>
      <P>
        Das Studium vermittelt umfassende Beratungskompetenzen. Dies ermöglicht
        es mir, meine langjährige Erfahrung im Bereich der Beratung, ergänzt um
        das akademische Fachwissen, noch kompetenter anwenden zu können.
      </P>
      <P>
        Seitdem berate ich zusätzlich Frauen und Männer in den oben genannten
        Krisensituationen, die nicht im Kontext Schwangerschaft/Geburt stehen
        freiberuflich und bei der Caritas.
      </P>
      <H3>Zusätzliche Qualifikationen</H3>
      <P>
        „Akute Traumatisierung in Beratung und Therapie“ — Seminar, Institut für
        Traumabearbeitung und Weiterbildung, Frankfurt am Main
      </P>
      <P>„Trauern – Wie geht das?“ — Fachtag, Haus am Dom, Frankfurt am Main</P>
      <P>
        „Trauer und belastende Lebenssituationen“ — Fachtag, Haus am Dom,
        Frankfurt am Main
      </P>
      <P>
        „Tot – Abschiede und Neuanfänge“ — Workshop, Haus am Dom, Frankfurt am
        Main
      </P>
      <P>
        „Die Zeit heilt nicht alle Wunden“ — Seminar, Caritas, Frankfurt am Main
      </P>
      <P>
        „Emotionale Stabilität gewinnen und dauerhaft bewahren trotz
        destruktiver Beziehungen im Privat- und Berufsleben“ — Therapeutisches
        Gruppenseminar, Essen
      </P>
      <P>
        "Wie sage ich es? — Die Konfrontation in der Beratung" — Seminar,
        Caritas, Frankfurt am Main
      </P>
      <P>
        "Verarbeiten von Verlusten" — Hospitation, Trauerzentrum, Frankfurt am
        Main
      </P>
    </Section>
    <Figure id={"kontakt"} caption="">
      <PictureSet imgObj={imgObj07} />
    </Figure>
    <Section>
      <H2>Kontakt</H2>
      <P>Sie möchten sich von mir beraten lassen?</P>
      <P>
        Ich freue mich auf Ihre Mail an{" "}
        <a href="mailto:info@krisenberatung-frankfurt.com">
          info@krisenberatung-frankfurt.com
        </a>{" "}
        oder Ihren Anruf an{" "}
        <span style={{ whiteSpace: "nowrap" }}>069–599761</span>.
      </P>
    </Section>
  </Layout>
)

export default IndexPage
